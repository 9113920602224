const baseUrl = process.env.REACT_APP_BASE_API_URL;

async function fetchApi(endpoint, options = {}) {
    const url = new URL(`${baseUrl}/${endpoint}`);

    const storedData = localStorage.getItem('auth-storage-petitprime');
    const parsedData = JSON.parse(storedData);

    // Extraer el token
    const token = parsedData?.state?.token;

    // Inicializar los headers
    const headers = new Headers();

    // Añadir el token si existe
    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
    }

    // Verificar si el body es FormData
    const isFormData = options.body instanceof FormData;

    // Solo establecer 'Content-Type' si el body no es FormData
    if (!isFormData) {
        headers.append('Content-Type', 'application/json; charset=utf-8');
        headers.append('Accept', 'application/json');
    }

    // Configurar las opciones de la solicitud
    const requestOptions = {
        ...options,
        headers,
    };

    // Asignar el body correctamente
    if (options.body && !['GET', 'DELETE'].includes(options.method)) {
        if (isFormData) {
            requestOptions.body = options.body; // No modificar FormData
        } else {
            requestOptions.body = JSON.stringify(options.body);
        }
    }

    const response = await fetch(url, requestOptions);

    // Manejo de errores
    if (!response.ok) {
        let errorMessage = 'Something went wrong';
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            const errorData = await response.json();
            errorMessage = errorData.message || errorMessage;
        } else {
            const errorText = await response.text();
            errorMessage = errorText || errorMessage;
        }
        throw new Error(errorMessage);
    }

    // Manejo de respuesta exitosa
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
        return await response.json();
    } else {
        // Si la respuesta no es JSON o está vacía, retornamos un objeto vacío o el texto
        return await response.text();
    }
}

export const get = (endpoint) => fetchApi(endpoint, { method: 'GET' });
export const post = (endpoint, body) => fetchApi(endpoint, { method: 'POST', body });
export const put = (endpoint, body) => fetchApi(endpoint, { method: 'PUT', body });
export const del = (endpoint, body) => fetchApi(endpoint, { method: 'DELETE', body });
export const patch = (endpoint, body) => fetchApi(endpoint, { method: 'PATCH', body });
