import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChartLine, faHeadset } from '@fortawesome/free-solid-svg-icons';


function Home() {

  return (
    <div className="min-h-screen bg-white text-blue-600">
      <Helmet>
        <title>Página de Inicio - PetitPrime</title>
        <meta name="description" content="Simplifica la creación de programas de fidelización con PetitPrime. Nuestra herramienta está diseñada para pequeños y medianos comercios, ayudándote a entender mejor a tus clientes a través de programas de fidelización efectivos." />
      </Helmet>

      <header className="bg-gradient-to-r from-blue-500 via-blue-300 to-blue-400 text-white text-center py-20">
        <h1 className="text-5xl font-extrabold">Simplifica la Creación de Programas de Fidelización con PetitPrime</h1>
        <p className="text-xl mt-4">Tu aliado estratégico en programas de fidelización, diseñado para pequeños y medianos comercios.</p>
        <p className="text-xl mt-4">30 días gratis y sin introducir tarjeta de crédito.</p>
        <div className="mt-6 space-y-4 md:space-x-4 md:space-y-0 flex flex-col md:flex-row justify-center items-center">
          <Link to="/register" className="bg-white hover:bg-gray-200 text-blue-600 font-bold py-3 px-6 rounded-full text-lg shadow-lg transition-all duration-300 ease-in-out">
            Registrate gratis
          </Link>
          <Link to="https://calendly.com/d/cpp2-rhr-c8q/reunion-de-30-minutos-petitprime" className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-3 px-6 rounded-full text-lg shadow-lg transition-all duration-300 ease-in-out">
            Solicita una reunion para dudas
          </Link>
        </div>
      </header>

      <section className="text-center py-16 bg-gray-100">
        <h2 className="text-3xl font-bold mb-6 text-black">Características Clave</h2>
        <div className="flex flex-col md:flex-row justify-center items-stretch">
          <div className="bg-white text-blue-600 p-8 m-4 rounded-lg shadow-lg flex flex-col items-center">
            <FontAwesomeIcon icon={faUser} className="text-4xl mb-4" />
            <h3 className="text-2xl font-semibold mb-4">Fácil de Usar</h3>
            <p>Interfaz intuitiva que permite a los usuarios gestionar programas de fidelización sin complicaciones.</p>
          </div>
          <div className="bg-white text-blue-600 p-8 m-4 rounded-lg shadow-lg flex flex-col items-center">
            <FontAwesomeIcon icon={faChartLine} className="text-4xl mb-4" />
            <h3 className="text-2xl font-semibold mb-4">Análisis en Tiempo Real</h3>
            <p>Obtén datos valiosos sobre el comportamiento de tus clientes y ajusta tus estrategias en consecuencia.</p>
          </div>
          <div className="bg-white text-blue-600 p-8 m-4 rounded-lg shadow-lg flex flex-col items-center">
            <FontAwesomeIcon icon={faHeadset} className="text-4xl mb-4" />
            <h3 className="text-2xl font-semibold mb-4">Soporte Personalizado</h3>
            <p>Asistencia dedicada para ayudarte a sacar el máximo provecho de nuestra plataforma.</p>
          </div>
        </div>
      </section>

      <section className="text-center py-16">
        <h2 className="text-3xl font-bold mb-6 text-black">Nuestros Clientes</h2>
        <div className="flex justify-center">
          <img src="logos/youtoo.png" alt="Logo Youtoo" className="max-h-12 mx-auto" />
        </div>
      </section>

      <section className="text-center py-16 bg-gray-100 text-black">
        <h2 className="text-3xl font-bold mb-6">Demostraciones Visuales</h2>
        <div className="flex justify-center">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/sGHYOPPSMlY"
            title="Demostración de PetitPrime"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>

      <section className="text-center py-16 text-black">
        <h2 className="text-3xl font-bold mb-6">Prueba Gratis</h2>
        <p className="text-lg mb-4">Regístrate ahora y obtén una prueba gratuita de 30 días.</p>
        <Link to="/register" className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-3 px-6 rounded-full text-lg shadow-lg transition-all duration-300 ease-in-out">
          Empieza Ahora
        </Link>
      </section>

      <section className="text-center py-16 text-black">
        <h2 className="text-3xl font-bold mb-6">Información de Contacto</h2>
        <p className="text-lg">¿Tienes alguna pregunta? Contáctanos:</p>
        <p className="text-lg mt-2">Email: info@petitstores.com</p>
      </section>
    </div>
  );
}

export default Home;
