import React, { useState, useEffect } from 'react';
import { get, del } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import useAuthStore from '../../store/authStore';
import ClientesSearchAndCreate from '../../components/clients/ClientesSearchAndCreate';
import ClientesTable from '../../components/clients/ClientesTable';
import CrearClienteModal from '../../components/clients/CrearClienteModal';

function ClientesPage() {
    const { user } = useAuthStore();
    const [clientes, setClientes] = useState([]);
    const [filteredClientes, setFilteredClientes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);  // Estado inicial del modal es false

    useEffect(() => {
        if (user) {
            fetchClientes();
        }
    }, [user]);

    const fetchClientes = async () => {
        try {
            const response = await get('clientes/byUser');

            setClientes(response);
            setFilteredClientes(response);
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "Esta acción no se puede deshacer",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        });
        if (result.isConfirmed) {
            try {
                await del(`clientes/${id}`);
                fetchClientes();
                Swal.fire({
                    title: 'Eliminado',
                    text: 'El cliente ha sido eliminado.',
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                });
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: error.message,
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
            }
        }
    };

    const handleSearch = (term) => {
        setSearchTerm(term);
        const lowerTerm = term.toLowerCase();
        const results = clientes.filter((cliente) =>
            (cliente.nombre && cliente.nombre.toLowerCase().includes(lowerTerm)) ||
            (cliente.email && cliente.email.toLowerCase().includes(lowerTerm)) ||
            (cliente.telefono && cliente.telefono.toString().toLowerCase().includes(lowerTerm))
        );
        setFilteredClientes(results);
    };


    const openModal = () => setIsModalOpen(true);  // Función para abrir el modal
    const closeModal = () => setIsModalOpen(false); // Función para cerrar el modal

    return (
        <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
            <h1 className="text-2xl md:text-4xl font-bold mb-2 md:mb-4">Clientes</h1>
            <p className="text-lg md:text-xl mb-4">Aquí puedes gestionar tus clientes.</p>

            {/* Buscador y botón para crear */}
            <ClientesSearchAndCreate
                onCreate={openModal}
                searchTerm={searchTerm}
                setSearchTerm={handleSearch}
            />

            {/* Tabla de clientes */}
            <ClientesTable clientes={filteredClientes} onDelete={handleDelete} fetchClientes={fetchClientes} />

            {/* Modal de creación de cliente */}
            <CrearClienteModal
                isOpen={isModalOpen}
                onClose={closeModal}
                onClienteCreated={fetchClientes}
            />
        </div>
    );
}

export default ClientesPage;