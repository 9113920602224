import React, { useState, useEffect } from 'react';
import { get, put } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import CrearProductoModal from '../../components/modals/CrearProductoModal';
import EditProductoModal from '../../components/modals/EditProductoModal';
import useAuthStore from '../../store/authStore';

function InventarioPage() {
    const [productos, setProductos] = useState([]);
    const [tiendas, setTiendas] = useState([]);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedProducto, setSelectedProducto] = useState(null);
    const [mostrarDesactivados, setMostrarDesactivados] = useState(false);
    const [selectedTienda, setSelectedTienda] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12; // Productos por página
    const { user } = useAuthStore();

    useEffect(() => {
        fetchTiendas();
        fetchProductos();
    }, [mostrarDesactivados, selectedTienda, currentPage]);

    const fetchProductos = async () => {
        try {
            const tiendaQuery = selectedTienda ? `&tiendaId=${selectedTienda}` : '';
            const response = await get(`productos?disponible=${mostrarDesactivados ? 'false' : 'true'}${tiendaQuery}`);

            setProductos(response);
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const fetchTiendas = async () => {
        try {
            const response = await get(`tiendas?usuario=${user._id}`);
            setTiendas(response);
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const handleToggleDisponibilidad = async (id) => {
        try {
            await put(`productos/${id}/disponible`);
            fetchProductos();
            Swal.fire({
                title: 'Éxito',
                text: 'La disponibilidad del producto se ha actualizado correctamente.',
                icon: 'success',
                confirmButtonText: 'Aceptar',
            });
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'No se pudo actualizar la disponibilidad del producto.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
        }
    };

    const filteredProductos = productos.filter(producto =>
        producto.nombre.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedProductos = filteredProductos.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredProductos.length / itemsPerPage);

    return (
        <div className="min-h-screen bg-gray-100 p-4 sm:p-6">
            <div className="container mx-auto">
                <h1 className="text-2xl sm:text-4xl font-bold mb-6 text-center text-gray-800">
                    Gestión de Inventario
                </h1>
                <div className="flex flex-col sm:flex-row items-center justify-between mb-6 gap-4">
                    <button
                        onClick={() => setIsCreateModalOpen(true)}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    >
                        Crear Producto
                    </button>
                    <button
                        onClick={() => setMostrarDesactivados(!mostrarDesactivados)}
                        className={`${mostrarDesactivados ? 'bg-green-500 hover:bg-green-600' : 'bg-red-500 hover:bg-red-600'
                            } text-white px-4 py-2 rounded`}
                    >
                        {mostrarDesactivados ? 'Ver Activos' : 'Ver Desactivados'}
                    </button>
                    <select
                        value={selectedTienda}
                        onChange={(e) => setSelectedTienda(e.target.value)}
                        className="bg-white border border-gray-300 text-gray-700 py-2 px-4 rounded"
                    >
                        <option value="">Todas las Tiendas</option>
                        {tiendas.map((tienda) => (
                            <option key={tienda._id} value={tienda._id}>
                                {tienda.nombre}
                            </option>
                        ))}
                    </select>
                    <input
                        type="text"
                        placeholder="Buscar productos..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="bg-white border border-gray-300 text-gray-700 py-2 px-4 rounded w-full sm:w-64"
                    />
                </div>

                <table className="table-auto w-full bg-white rounded-lg shadow-md">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2 text-left">Nombre</th>
                            <th className="px-4 py-2 text-left">Precio</th>
                            <th className="px-4 py-2 text-left">Disponible</th>
                            <th className="px-4 py-2 text-left">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedProductos.map((producto) => (
                            <tr key={producto._id} className="border-b hover:bg-gray-100">
                                <td className="px-4 py-2">{producto.nombre}</td>
                                <td className="px-4 py-2">${producto.precio.toFixed(2)}</td>
                                <td className="px-4 py-2">{producto.disponible ? 'Sí' : 'No'}</td>
                                <td className="px-4 py-2">
                                    <button
                                        onClick={() => {
                                            setSelectedProducto(producto);
                                            setIsEditModalOpen(true);
                                        }}
                                        className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600 text-sm"
                                    >
                                        Editar
                                    </button>
                                    <button
                                        onClick={() => handleToggleDisponibilidad(producto._id)}
                                        className="ml-2 bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 text-sm"
                                    >
                                        {producto.disponible ? 'Desactivar' : 'Activar'}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="mt-6 flex justify-center">
                    {Array.from({ length: totalPages }).map((_, index) => (
                        <button
                            key={index}
                            onClick={() => setCurrentPage(index + 1)}
                            className={`px-4 py-2 mx-1 rounded ${currentPage === index + 1
                                ? 'bg-blue-500 text-white'
                                : 'bg-gray-300 text-gray-700 hover:bg-gray-400'
                                }`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>

            {isCreateModalOpen && (
                <CrearProductoModal
                    isOpen={isCreateModalOpen}
                    onClose={() => setIsCreateModalOpen(false)}
                    onProductoCreated={fetchProductos}
                />
            )}
            {isEditModalOpen && selectedProducto && (
                <EditProductoModal
                    isOpen={isEditModalOpen}
                    producto={selectedProducto}
                    onClose={() => setIsEditModalOpen(false)}
                    onProductoUpdated={fetchProductos}
                />
            )}
        </div>
    );
}

export default InventarioPage;
