import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import { FaTrophy } from 'react-icons/fa';

const TrabajadorDashboard = () => {
  const { trabajadorId } = useParams();
  const [tiendas, setTiendas] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTiendas = async () => {
      try {
        // Asume que la API retorna los detalles completos de las tiendas, no solo los IDs
        const tiendaDetails = await get(`trabajadores/${trabajadorId}/tiendas`);
        setTiendas(tiendaDetails);
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    };

    fetchTiendas();
  }, [trabajadorId]);

  const handleViewClientes = (tiendaId) => {
    navigate(`/trabajador/${trabajadorId}/tiendas/${tiendaId}/clientes`);
  };

  const handleViewRanking = () => {
    navigate(`/trabajadores/ranking`);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-6">
      <div className="w-full max-w-3xl">
        <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">Dashboard de Trabajador</h1>
        <div className="bg-white p-6 rounded-lg shadow-md w-full">
          <h2 className="text-2xl font-bold mb-4 text-center text-gray-700">Tiendas Asociadas</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {tiendas.map((tienda) => (
              <div key={tienda._id} className="bg-gray-50 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                <h3 className="text-xl font-semibold mb-2 text-gray-700">{tienda.nombre}</h3>
                <p className="text-gray-600 mb-4">{tienda.descripcion}</p>
                <div className="flex justify-center">
                  <button
                    onClick={() => handleViewClientes(tienda._id)}
                    className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors duration-300"
                  >
                    Ver o Crear Clientes
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrabajadorDashboard;
