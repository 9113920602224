import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const LikertChart = ({ data }) => {
  const options = {
    scales: {
      x: {
        ticks: {
          callback: function (value) {
            // Truncate the labels to 50 characters, with ellipsis if longer
            return value.length > 50 ? `${value.substring(0, 47)}...` : value;
          },
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          font: {
            size: 10, // Smaller font size for labels
          }
        }
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Puntuación Media',
          font: {
            size: 14 // Adjust font size for readability
          }
        }
      }
    },
    maintainAspectRatio: true,
    responsive: true,
    devicePixelRatio: 1,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Promedio de Respuestas por Pregunta'
      }
    }
  };

  // Render the list of full questions
  const questionList = data.labels.map((label, index) => (
    <li key={index}>{label}</li>
  ));

  return (
    <div className="my-8">
      <div className="relative h-64 md:h-96">
        <Bar data={data} options={options} />
      </div>
      <div className="mt-4">
        <h3 className="text-lg font-semibold">Lista Completa de Preguntas:</h3>
        <ol className="list-decimal ml-4">{questionList}</ol>
      </div>
    </div>
  );
};

export default LikertChart;
