import React, { useState, useEffect } from 'react';
import { get, post, del } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

const TrabajadorModal = ({ show, onClose, tiendaId }) => {
  const [trabajadores, setTrabajadores] = useState([]);
  const [trabajadorEmail, setTrabajadorEmail] = useState('');

  useEffect(() => {
    if (show) {
      fetchTrabajadores(tiendaId);
    }
  }, [show, tiendaId]);

  const fetchTrabajadores = async (tiendaId) => {
    try {
      const response = await get(`tiendas/${tiendaId}/trabajadores`);
      setTrabajadores(response);
    } catch (error) {
      console.error('Error fetching trabajadores:', error);
    }
  };

  const handleAddTrabajador = async () => {
    try {
      await post(`trabajadores/add-to-tienda/${tiendaId}`, { email: trabajadorEmail });
      fetchTrabajadores(tiendaId);
      setTrabajadorEmail('');
      Swal.fire({
        title: 'Éxito',
        text: 'Trabajador añadido o invitación enviada correctamente',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleDeleteTrabajador = async (trabajadorId) => {
    try {
      await del(`trabajadores/${trabajadorId}/tiendas/${tiendaId}`);
      fetchTrabajadores(tiendaId);
      Swal.fire({
        title: 'Éxito',
        text: 'Trabajador eliminado correctamente',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  if (!show) return null;

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen p-4 text-center sm:p-0">
        {/* Fondo oscuro */}
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        {/* Contenido del modal */}
        <div
          className="bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full max-w-md sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-title"
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h2 className="text-xl md:text-2xl font-bold mb-4">Añadir Trabajador</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleAddTrabajador();
              }}
            >
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="trabajadorEmail"
                >
                  Email del Trabajador
                </label>
                <input
                  type="email"
                  name="trabajadorEmail"
                  value={trabajadorEmail}
                  onChange={(e) => setTrabajadorEmail(e.target.value)}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex justify-center">
                <button
                  type="button"
                  onClick={onClose}
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700 mr-2"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                  Añadir
                </button>
              </div>
            </form>
            <h3 className="text-lg md:text-xl font-bold mt-6">Trabajadores</h3>
            <ul className="mt-4 space-y-2">
              {trabajadores.map((trabajador) => (
                <li
                  key={trabajador._id}
                  className="flex flex-col sm:flex-row justify-between items-start sm:items-center bg-gray-100 p-2 rounded"
                >
                  <span className="text-sm">
                    {trabajador.nombre} - {trabajador.email}
                  </span>
                  <button
                    className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-700 mt-2 sm:mt-0"
                    onClick={() => handleDeleteTrabajador(trabajador._id)}
                  >
                    Eliminar
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrabajadorModal;
