// src/components/ProtectedLayout.jsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../common/Sidebar';

const ProtectedLayout = () => {
  return (
    <div className="flex min-h-screen">
      <div className="flex-grow p-6">
        <Outlet /> {/* Aquí es donde se renderizan los componentes hijos */}
      </div>
    </div>
  );
};

export default ProtectedLayout;
