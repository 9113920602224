import React, { useState, useEffect } from 'react';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import { useParams, useNavigate } from 'react-router-dom';
import {
  FaUsers,
  FaStore,
  FaChartLine,
  FaMoneyBillWave,
  FaRetweet,
  FaChartPie,
  FaInfoCircle
} from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import StatCard from '../../components/statistic/StatCard';

const StatisticsPage = () => {
  const { marcaId } = useParams();
  const [selectedMarcaId, setSelectedMarcaId] = useState(marcaId || '');
  const [marcas, setMarcas] = useState([]);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);  // Estado para el loader
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMarcas = async () => {
      try {
        setLoading(true);
        const response = await get('marcas');
        setMarcas(response);

        if (!marcaId && response.length > 0) {
          setSelectedMarcaId(response[0]._id);
          navigate(`/statistics/${response[0]._id}`);
        }
      } catch (error) {
        console.error('Error fetching marcas:', error);
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      } finally {
        setLoading(false);
      }
    };

    fetchMarcas();
  }, [marcaId, navigate]);

  useEffect(() => {
    if (selectedMarcaId) {
      const fetchStats = async () => {
        try {
          setLoading(true);
          const response = await get(`statistics/marca/${selectedMarcaId}/detailed`);
          setStats(response);
        } catch (error) {
          setError('No hay suficientes datos para mostrar estadísticas.');
          console.error('Error fetching statistics:', error);
          Swal.fire({
            title: 'Error',
            text: error.message || 'No hay suficientes datos para mostrar estadísticas.',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        } finally {
          setLoading(false);
        }
      };

      fetchStats();
    }
  }, [selectedMarcaId]);

  const handleMarcaChange = (e) => {
    const newMarcaId = e.target.value;
    setSelectedMarcaId(newMarcaId);
    navigate(`/statistics/${newMarcaId}`);
  };

  if (loading) {
    return <div className="p-6 text-center">Cargando estadísticas...</div>;
  }

  if (error) {
    return <div className="p-6 text-red-500">{error}</div>;
  }

  if (!stats || !marcas.length) {
    return <div className="p-6">No hay datos disponibles.</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4 md:p-6">
      <div className="container mx-auto">
        <h1 className="text-2xl md:text-4xl font-bold mb-4 md:mb-6 text-center text-gray-800">
          Estadísticas
        </h1>

        {/* Selector de Marca */}
        <div className="mb-6">
          <label
            htmlFor="marca-selector"
            className="block text-lg font-medium text-gray-700"
          >
            Selecciona una Marca:
          </label>
          <select
            id="marca-selector"
            value={selectedMarcaId}
            onChange={handleMarcaChange}
            className="mt-2 block w-full md:w-1/2 lg:w-1/3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            {marcas.map((marca) => (
              <option key={marca._id} value={marca._id}>
                {marca.nombre}
              </option>
            ))}
          </select>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
          <StatCard icon={<FaUsers />} title="Clientes" value={stats.totalClientes} tooltip="Número total de clientes registrados para esta marca." />
          <StatCard icon={<FaStore />} title="Tiendas" value={stats.totalTiendas} tooltip="Número total de tiendas asociadas con esta marca." />
          <StatCard icon={<FaChartLine />} title="Puntos Totales" value={stats.totalPuntos} tooltip="Total de puntos acumulados por todos los clientes de la marca." />
          <StatCard icon={<FaRetweet />} title="Canjes Totales" value={stats.totalCanjes} tooltip="Número total de puntos canjeados por los clientes." />
          <StatCard icon={<FaMoneyBillWave />} title="Valor Promedio de Transacción" value={stats.averageTransactionValue.toFixed(2)} tooltip="Promedio del valor de cada transacción realizada por los clientes." />
          <StatCard icon={<FaRetweet />} title="Tasa de Redención de Puntos" value={`${stats.redemptionRate.toFixed(2)}%`} tooltip="Porcentaje de puntos acumulados que han sido canjeados por los clientes." />

          {/* Frecuencia de Visita */}
          <StatCard icon={<FaChartPie />} title="Frecuencia de Visita Diaria" value={stats.visitFrequency.daily.toFixed(2)} tooltip="Promedio de visitas diarias por cliente." />
          <StatCard icon={<FaChartPie />} title="Frecuencia de Visita Semanal" value={stats.visitFrequency.weekly.toFixed(2)} tooltip="Promedio de visitas semanales por cliente." />
          <StatCard icon={<FaChartPie />} title="Frecuencia de Visita Mensual" value={stats.visitFrequency.monthly.toFixed(2)} tooltip="Promedio de visitas mensuales por cliente." />

          <StatCard icon={<FaRetweet />} title="Tasa de Retención" value={`${stats.retentionRate.toFixed(2)}%`} tooltip="Porcentaje de clientes que realizan compras repetidas." />
        </div>
        <Tooltip id="tooltip" />
      </div>
    </div>
  );
};

export default StatisticsPage;
