// src/pages/PricingPage.jsx

import React, { useEffect, useState } from 'react';
import PricingCard from '../../components/pricing/PricingCard';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../store/authStore';

const PricingPage = () => {
    const [pricingConfig, setPricingConfig] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState('peru'); // Perú como país por defecto
    const [selectedBillingPeriod, setSelectedBillingPeriod] = useState('monthly'); // Período de facturación por defecto
    const navigate = useNavigate();
    const { user } = useAuthStore();
    const isLoggedIn = user && user.isAuthenticated;

    useEffect(() => {
        const fetchPricingConfig = async () => {
            try {
                const config = await get('payments/pricing-config');
                setPricingConfig(config);
            } catch (error) {
                console.error('Error fetching pricing config:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'No se pudo obtener la configuración de precios. Por favor, inténtelo más tarde.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        };

        fetchPricingConfig();
    }, []);

    const handleSelectPlan = (plan) => {
        if (!isLoggedIn) {
            Swal.fire({
                title: 'Acceso Denegado',
                text: 'Debes iniciar sesión para suscribirte a un plan.',
                icon: 'warning',
                confirmButtonText: 'Iniciar Sesión',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/login');
                }
            });
            return;
        }

        navigate('/subscribe', { state: { plan } });
    };

    const handleCountryChange = (e) => {
        setSelectedCountry(e.target.value);
    };

    const handleBillingPeriodChange = (period) => {
        setSelectedBillingPeriod(period);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="text-xl">Cargando...</div>
            </div>
        );
    }

    if (!pricingConfig) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="text-xl">No se pudo cargar la configuración de precios.</div>
            </div>
        );
    }

    // Obtener la configuración del país seleccionado
    const countryConfig = pricingConfig[selectedCountry];
    if (!countryConfig) {
        Swal.fire({
            title: 'Error',
            text: `No hay configuración para el país seleccionado: ${selectedCountry}`,
            icon: 'error',
            confirmButtonText: 'Aceptar',
        });
        return null;
    }

    // Obtener la moneda del país seleccionado
    // Asumimos que todas las configuraciones de planes en un país tienen la misma moneda
    const firstPlanKey = Object.keys(countryConfig).find((key) => key !== 'currency');
    const currency = firstPlanKey ? countryConfig[firstPlanKey].currency : 'USD';

    // Obtener la lista de países disponibles desde pricingConfig
    const availableCountries = pricingConfig ? Object.keys(pricingConfig) : [];

    return (
        <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <h1 className="text-5xl font-extrabold text-center mb-6">Nuestros Planes</h1>
                {/* Mensaje de Trial Gratuito */}
                <div className="mb-8 text-center">
                    <p className="text-lg text-gray-700">
                        Prueba gratis durante 30 días sin necesidad de introducir una tarjeta de crédito.
                    </p>
                </div>

                {/* Selector de País */}
                <div className="mb-6 flex justify-center">
                    <label htmlFor="country" className="mr-2 text-gray-700 font-semibold">
                        Selecciona tu País:
                    </label>
                    <select
                        id="country"
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    >
                        {availableCountries.map((country) => (
                            <option key={country} value={country.toLowerCase()}>
                                {country.charAt(0).toUpperCase() + country.slice(1)}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Opciones para Seleccionar Período de Facturación */}
                <div className="flex justify-center mb-6">
                    <div className="space-x-4">
                        <button
                            onClick={() => handleBillingPeriodChange('monthly')}
                            className={`px-4 py-2 rounded ${selectedBillingPeriod === 'monthly'
                                ? 'bg-blue-500 text-white'
                                : 'bg-gray-200 text-gray-700'
                                }`}
                        >
                            Mensual
                        </button>
                        <button
                            onClick={() => handleBillingPeriodChange('annual')}
                            className={`px-4 py-2 rounded ${selectedBillingPeriod === 'annual'
                                ? 'bg-blue-500 text-white'
                                : 'bg-gray-200 text-gray-700'
                                }`}
                        >
                            Anual
                        </button>
                    </div>
                </div>

                {/* Lista de Planes */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {Object.keys(countryConfig)
                        .filter((plan) => plan !== 'currency')
                        .map((plan) => (
                            <PricingCard
                                key={plan}
                                plan={plan}
                                price={countryConfig[plan]}
                                currency={currency} // Pasar la moneda del país seleccionado
                                billingPeriod={selectedBillingPeriod} // Utiliza el período seleccionado
                                features={countryConfig[plan].features}
                                onSelect={handleSelectPlan}
                                isCurrent={user?.plan === plan}
                                isLoggedIn={isLoggedIn}
                            />
                        ))}
                </div>
            </div>
        </div>
    );

};

export default PricingPage;
