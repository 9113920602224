// src/components/tables/ClientesTable.jsx
import React, { useState } from 'react';
import { FaTrash, FaEdit, FaEnvelope } from 'react-icons/fa';
import EditClienteModal from '../modals/EditClienteModal';
import { patch } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

const ClientesTable = ({ clientes, onDelete, fetchClientes }) => {
    const [editingCliente, setEditingCliente] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleEditCliente = (cliente) => {
        setEditingCliente(cliente);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setEditingCliente(null);
        setShowModal(false);
    };

    const handleSaveCliente = async (updatedCliente) => {
        try {
            // Desestructurar para eliminar propiedades no deseadas
            const { _id, tickets, fechaCreacion, __v, geographic_info, ...rest } = updatedCliente;

            // Desestructurar geographic_info para eliminar _id dentro de él
            const { _id: geoId, ...geoInfo } = geographic_info;

            // Crear el payload sin las propiedades no permitidas
            const payload = {
                ...rest,
                geographic_info: geoInfo,
            };

            // Realizar la solicitud PATCH
            await patch(`clientes/${_id}`, payload);

            // Refrescar la lista de clientes
            fetchClientes();

            // Cerrar el modal
            handleCloseModal();

            // Mostrar mensaje de éxito
            Swal.fire({
                title: 'Éxito',
                text: 'Cliente actualizado correctamente',
                icon: 'success',
                confirmButtonText: 'Aceptar',
            });
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
        }
    };

    const handleResendTicket = async (clienteId) => {
        try {
            const response = await patch(`tickets/cliente/${clienteId}/resend`);
            console.log(response);

            Swal.fire({
                title: 'Éxito',
                text: response.message || 'Ticket reenviado correctamente',
                icon: 'success',
                confirmButtonText: 'Aceptar',
            });
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
        }
    };

    return (
        <div>
            {/* Tabla para pantallas medianas y grandes */}
            <div className="hidden md:block overflow-x-auto">
                <table className="min-w-full w-full table-auto">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="px-4 py-2 text-left">Nombre</th>
                            <th className="px-4 py-2 text-left">Apellido</th>
                            <th className="px-4 py-2 text-left">Email</th>
                            <th className="px-4 py-2 text-left">Teléfono</th>
                            <th className="px-4 py-2 text-left">Cumpleaños</th>
                            <th className="px-4 py-2 text-left">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientes.map((cliente) => (
                            <tr key={cliente._id} className="border-t border-gray-300">
                                <td className="px-4 py-2">{cliente.nombre}</td>
                                <td className="px-4 py-2">{cliente.apellido}</td>
                                <td className="px-4 py-2">{cliente.email}</td>
                                <td className="px-4 py-2">{cliente.telefono || 'N/A'}</td>
                                <td className="px-4 py-2">
                                    {cliente.fechaCumpleanos
                                        ? new Date(cliente.fechaCumpleanos).toLocaleDateString()
                                        : 'N/A'}
                                </td>
                                <td className="px-4 py-2 flex space-x-2">
                                    <button
                                        onClick={() => handleEditCliente(cliente)}
                                        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700"
                                    >
                                        <FaEdit />
                                    </button>
                                    <button
                                        onClick={() => handleResendTicket(cliente._id)}
                                        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700"
                                    >
                                        <FaEnvelope />
                                    </button>
                                    <button
                                        onClick={() => onDelete(cliente._id)}
                                        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700"
                                    >
                                        <FaTrash />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Tarjetas para pantallas pequeñas */}
            <div className="md:hidden space-y-4">
                {clientes.map((cliente) => (
                    <div
                        key={cliente._id}
                        className="border border-gray-300 rounded p-4 shadow-sm"
                    >
                        <div className="flex justify-between items-center mb-2">
                            <h2 className="text-lg font-bold">
                                {cliente.nombre} {cliente.apellido}
                            </h2>
                            <div className="flex space-x-2">
                                <button
                                    onClick={() => handleEditCliente(cliente)}
                                    className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700"
                                >
                                    <FaEdit />
                                </button>
                                <button
                                    onClick={() => handleResendTicket(cliente._id)}
                                    className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700"
                                >
                                    <FaEnvelope />
                                </button>
                                <button
                                    onClick={() => onDelete(cliente._id)}
                                    className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700"
                                >
                                    <FaTrash />
                                </button>
                            </div>
                        </div>
                        <p className="text-sm">
                            <span className="font-semibold">Email:</span> {cliente.email}
                        </p>
                        <p className="text-sm">
                            <span className="font-semibold">Teléfono:</span>{' '}
                            {cliente.telefono || 'N/A'}
                        </p>
                        <p className="text-sm">
                            <span className="font-semibold">Cumpleaños:</span>{' '}
                            {cliente.fechaCumpleanos
                                ? new Date(cliente.fechaCumpleanos).toLocaleDateString()
                                : 'N/A'}
                        </p>
                    </div>
                ))}
            </div>

            {/* Modal para editar cliente */}
            {showModal && editingCliente && (
                <EditClienteModal
                    cliente={editingCliente}
                    onClose={handleCloseModal}
                    onSave={handleSaveCliente}
                />
            )}
        </div>
    );

};

export default ClientesTable;
