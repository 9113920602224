// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/common/Home';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Dashboard from './pages/common/Dashboard';
import ProtectedRoute from './components/routes/ProtectedRoute';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Sidebar from './components/common/Sidebar';
import PublicRoute from './components/routes/PublicRoute';
import TermsAndConditions from './pages/common/TermsAndConditions';
import CookiePolicyPage from './pages/common/CookiePolicyPage';
import PaymentResult from './pages/payment/PaymentResult';
import Settings from './pages/cliente/Settings';
import ResetPassword from './pages/auth/ResetPassword';
import ChangePassword from './pages/auth/ChangePassword';
import EmailVerification from './pages/auth/EmailVerification';
import SubscriptionPage from './pages/payment/SubscriptionPage';
import ProtectedLayout from './components/routes/ProtectedLayout';
import MarcasPage from './pages/tienda/MarcasPage';
import TiendasPage from './pages/tienda/TiendasPage';
import ClientesList from './pages/cliente/ClientesList';
import CrearCliente from './pages/cliente/CrearCliente';
import HistoryPage from './pages/cliente/HistoryPage';
import TrabajadorLogin from './pages/auth/TrabajadorLogin';
import TrabajadorDashboard from './pages/trabajador/TrabajadorDashboard';
import TrabajadorRegister from './pages/auth/TrabajadorRegister';
import ProtectedTrabajadorRoute from './components/routes/ProtectedTrabajadorRoute';
import PricingPage from './pages/common/PricingPage';

//sureys
import DashboardSurveys from './pages/feedback/DashboardSurveys';
import SurveyCreator from './pages/feedback/SurveyCreator';
import SurveyResults from './pages/feedback/SurveyResults';
import SurveyPage from './pages/feedback/SurveyPage';
import CookieConsent from './components/common/CookieConsent';
import TicketView from './pages/cliente/TicketView';
import HeaderTrabajadores from './components/common/HeaderTrabajadores';

// StatisticsPage
import StatisticsDashboard from './pages/statistics/StatisticsDashboard';
import StatisticsPage from './pages/statistics/StatisticsPage';
import StoreStatisticsPage from './pages/statistics/StoreStatisticsPage';
import CustomerStatisticsPage from './pages/statistics/CustomerStatisticsPage';
import ClientesPage from './pages/cliente/ClientesPage';
import ImportClientesPage from './pages/cliente/ImportClientesPage';
import PrizeHistoryPage from './pages/cliente/PrizeHistoryPage';
import InventarioPage from './pages/inventario/InventarioPage';
import { get } from './helpers/apiHelper';
import AlertModal from './components/modals/AlertModal';
import useAuthStore from './store/authStore';
import AlertPage from './pages/alertas/AlertPage';


function App() {

  const { trabajador } = useAuthStore();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { user } = useAuthStore();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const fetchMarcasAndAlerts = async () => {
      try {
        if (user?.userId) {
          const responseMarcas = await get(`marcas/user/${user.userId}`);
          setMarcas(responseMarcas);

          if (responseMarcas.length > 0) {
            const alertPromises = responseMarcas.map((marca) =>
              get(`alertas/marca/${marca._id}`)
            );
            const allAlerts = (await Promise.all(alertPromises)).flat();
            setAlerts(allAlerts);

            if (allAlerts.length && !sessionStorage.getItem('alertModalShown')) {
              setShowModal(true);
              sessionStorage.setItem('alertModalShown', 'true');
            }
          }
        }
      } catch (error) {
        console.error('Error fetching marcas or alerts:', error);
      }
    };

    fetchMarcasAndAlerts();
  }, [user]);

  return (
    <div>
      {showModal && alerts.length > 0 && (
        <AlertModal alerts={alerts} onClose={() => setShowModal(false)} />
      )}

      <Router>
        {trabajador ? (
          <HeaderTrabajadores />
        ) : (
          <Header toggleSidebar={toggleSidebar} />
        )}
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <main>
          <Routes>
            {/* Envoltura para rutas publicas */}
            <Route element={<PublicRoute />}>
              <Route path="/" element={<Home />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/trabajador/login" element={<TrabajadorLogin />} />
              <Route path="/trabajador/register" element={<TrabajadorRegister />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/verify" element={<EmailVerification />} />
              <Route path="/tickets/:ticketUrl" element={<TicketView />} />
            </Route>

            {/* Envoltura para rutas protegidas */}
            <Route element={<ProtectedRoute />}>
              <Route element={<ProtectedLayout />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/subscribe" element={<SubscriptionPage />} />
                <Route path="/marcas" element={<MarcasPage />} />
                <Route path="/tiendas" element={<TiendasPage />} />
                <Route path="/clientes" element={<ClientesPage />} />
                <Route path="/clientes/importar" element={<ImportClientesPage />} />
                <Route path="/tiendas/:tiendaId/clientes" element={<ClientesList />} />
                <Route path="/tiendas/:tiendaId/clientes/create" element={<CrearCliente />} />
                <Route path="/history/:clienteId" element={<HistoryPage />} />
                <Route path="/canjeos/:clienteId" element={<PrizeHistoryPage />} />
                <Route path="/inventario" element={<InventarioPage />} />
                <Route path="/alertas" element={<AlertPage />} />

                {/*Surveis routes*/}
                <Route path="/dashboard-surveys" element={<DashboardSurveys />} />
                <Route path="/create-survey" element={<SurveyCreator />} />
                <Route path="/survey-results/:surveyId" element={<SurveyResults />} />

                {/* Statistics Routes */}
                <Route path="/statistics/*" element={<StatisticsDashboard />}>
                  <Route path="marca/:marcaId?" element={<StatisticsPage />} />
                  <Route path="tienda/:tiendaId?" element={<StoreStatisticsPage />} />
                  <Route path="cliente/:clienteId?" element={<CustomerStatisticsPage />} />
                  <Route path="" element={<Navigate replace to="marca" />} />
                </Route>
              </Route>
            </Route>

            {/* Envoltura para rutas protegidas del trabajador */}
            <Route element={<ProtectedTrabajadorRoute />}>
              <Route path="/trabajador/:trabajadorId/dashboard" element={<TrabajadorDashboard />} />
              <Route path="/trabajador/:trabajadorId/tiendas/:tiendaId/clientes" element={<ClientesList />} />
              <Route path="/trabajador/:trabajadorId/tiendas/:tiendaId/clientes/create" element={<CrearCliente />} />
            </Route>

            <Route path="/payment-result" element={<PaymentResult />} />
            <Route path="/surveys/:surveyId" element={<SurveyPage />} />
            <Route path="/terminos" element={<TermsAndConditions />} />
            <Route path="/politica-de-cookies" element={<CookiePolicyPage />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
          <CookieConsent />
        </main>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
