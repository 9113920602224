// src/components/PrizeHistoryPage.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

const PrizeHistoryPage = () => {
    const { clienteId } = useParams();
    const [canjeos, setCanjeos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const navigate = useNavigate();

    useEffect(() => {
        fetchAllCanjeos();
    }, []);

    const fetchAllCanjeos = async () => {
        try {
            const response = await get(`tickets/cliente/${clienteId}/canjeos`);

            // Mapear los canjeos para extraer solo las propiedades necesarias
            const mappedCanjeos = response.map(canjeo => ({
                fecha: canjeo.fecha,
                puntos: canjeo.puntos,
                premioNombre: canjeo.premioNombre,
                premioDescripcion: canjeo.premioDescripcion,
                tiendaNombre: canjeo.tiendaNombre,
            }));

            setCanjeos(mappedCanjeos);
        } catch (error) {
            console.error('Error fetching canjeos:', error);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    // Paginación
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = canjeos.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(canjeos.length / itemsPerPage);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'Fecha no disponible';
        const date = new Date(dateString);
        if (isNaN(date)) return 'Invalid Date';
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    const formatTime = (dateString) => {
        if (!dateString) return 'Hora no disponible';
        const date = new Date(dateString);
        if (isNaN(date)) return 'Invalid Time';
        return date.toLocaleTimeString();
    };

    return (
        <div className="bg-white p-6 rounded-lg shadow-md md:ml-64">
            <h1 className="text-4xl font-bold mb-4">Historial de Canjeos de Premios</h1>
            {/* Eliminar el select ya que no hay múltiples tickets */}
            <ul className="divide-y divide-gray-200">
                {currentItems.map((canjeo, index) => (
                    <li key={index} className="py-4">
                        <div className="flex justify-between items-center">
                            <div>
                                <p className="text-lg font-semibold text-gray-900">
                                    Fecha: <span className="font-normal">{formatDate(canjeo.fecha)}</span>
                                </p>
                                <p className="text-lg font-semibold text-gray-900">
                                    Puntos: <span className="font-normal">{canjeo.puntos}</span>
                                </p>
                                <p className="text-lg font-semibold text-gray-900">
                                    Premio: <span className="font-normal">{canjeo.premioNombre}</span>
                                </p>
                                <p className="text-lg font-semibold text-gray-900">
                                    Descripción: <span className="font-normal">{canjeo.premioDescripcion}</span>
                                </p>
                                <p className="text-lg font-semibold text-gray-900">
                                    Tienda: <span className="font-normal">{canjeo.tiendaNombre}</span>
                                </p>
                            </div>
                            <div>
                                <p className="text-sm text-gray-500">{formatTime(canjeo.fecha)}</p>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            {canjeos.length === 0 && (
                <p className="text-center text-gray-500">No hay canjeos disponibles.</p>
            )}
            <div className="flex justify-between items-center mt-6">
                <button
                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                    onClick={handleBack}
                >
                    Volver
                </button>
                <div className="flex space-x-1">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => paginate(index + 1)}
                            className={`px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );

};

export default PrizeHistoryPage;
