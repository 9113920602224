// src/pages/cliente/SubscriptionPage.jsx

import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { get } from '../../helpers/apiHelper';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuthStore from '../../store/authStore';
import PricingCard from '../../components/pricing/PricingCard';

function SubscriptionPage() {
  const [userData, setUserData] = useState(null);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState('monthly');
  const navigate = useNavigate();
  const { user, trabajador, isAuthenticated } = useAuthStore();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user && user.userId) {
          const userResponse = await get(`users/${user.userId}`);
          const plansResponse = await get('payments/pricing-config');

          if (userResponse && plansResponse) {
            setUserData(userResponse);

            // Determinar el período de facturación actual del usuario basado en plan y pagoPlan
            const currentPlan = userResponse.plan; // Plan actual
            const currentPaymentPlan = userResponse.pagoPlan; // Plan de pago (mensual o anual)

            // Establecer el periodo de facturación basado en el plan actual
            const billingPeriod = currentPaymentPlan === 'annual' ? 'annual' : 'monthly';
            setSelectedBillingPeriod(billingPeriod); // Establecer el periodo de facturación

            const normalizedCountry = userResponse.pais.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            const userCountryPlans = plansResponse[normalizedCountry];

            if (!userCountryPlans) {
              throw new Error(`No hay planes disponibles para el país: ${userResponse.pais}`);
            }

            const plansArray = Object.keys(userCountryPlans).map((planKey) => ({
              plan: planKey,
              price: {
                monthly: userCountryPlans[planKey].monthly,
                annual: userCountryPlans[planKey].annual,
                currency: userCountryPlans[planKey].currency,
                features: userCountryPlans[planKey].features,
              },
            }));

            setPlans(plansArray);
          }
        } else {
          throw new Error('User ID is undefined');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        Swal.fire('Error', 'No se pudo obtener los datos necesarios.', 'error').then(() => {
          navigate('/login');
        });
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchData();
    } else {
      setLoading(false);
      Swal.fire('Error', 'Usuario no autenticado.', 'error').then(() => {
        navigate('/login');
      });
    }
  }, [user, navigate, isAuthenticated]);

  const handleSubscribe = async (plan) => {
    try {
      const selectedPlan = plan;
      const billingPeriod = selectedBillingPeriod;

      const response = await get(`payments/start-payment/${user.userId}/${selectedPlan}/${billingPeriod}`);

      if (response.paymentUrl) {
        window.location.href = response.paymentUrl;
      } else {
        throw new Error('No se pudo crear la sesión de pago.');
      }
    } catch (error) {
      console.error('Error starting payment:', error);
      Swal.fire('Error', error.message, 'error');
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const success = queryParams.get('success');
    const cancelled = queryParams.get('cancelled');
    const userId = queryParams.get('userId');
    const sessionId = queryParams.get('session_id');

    if (success === 'true' && userId && sessionId) {
      (async () => {
        try {
          const verifyResponse = await get(`payments/verify/${userId}/${sessionId}`);
          Swal.fire('Éxito', verifyResponse.message || 'Suscripción actualizada correctamente.', 'success').then(() => {
            navigate('/dashboard');
          });
        } catch (error) {
          console.error('Error verifying payment:', error);
          Swal.fire('Error', 'No se pudo verificar el pago.', 'error').then(() => {
            navigate('/dashboard');
          });
        }
      })();
    }

    if (cancelled === 'true') {
      Swal.fire('Cancelado', 'El pago ha sido cancelado.', 'info').then(() => {
        navigate('/dashboard');
      });
    }
  }, [location.search, navigate]);

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-100 p-4">
        <div className="text-xl">Cargando...</div>
      </div>
    );
  }

  if (!userData) {
    return null;
  }

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 p-4">
      <h1 className="text-2xl font-semibold mb-6">Gestionar Suscripción</h1>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-6 w-full max-w-2xl">
        <h2 className="text-xl font-bold mb-4">Información del Usuario</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <p className="text-gray-700"><span className="font-semibold">Nombre:</span> {userData.nombre}</p>
          <p className="text-gray-700"><span className="font-semibold">Apellido:</span> {userData.apellido}</p>
          <p className="text-gray-700"><span className="font-semibold">Correo Electrónico:</span> {userData.email}</p>
          <p className="text-gray-700"><span className="font-semibold">País:</span> {userData.pais}</p>
          <p className="text-gray-700"><span className="font-semibold">Rol:</span> {trabajador ? 'TRABAJADOR_ROLE' : 'USER_ROLE'}</p>
        </div>
      </div>

      {!trabajador && userData && (
        <div className="w-full max-w-4xl">
          <h2 className="text-xl font-bold mb-4">Selecciona tu Plan</h2>

          <div className="flex space-x-4 mb-6">
            <button
              onClick={() => setSelectedBillingPeriod('monthly')}
              className={`px-4 py-2 rounded ${selectedBillingPeriod === 'monthly' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            >
              Mensual
            </button>
            <button
              onClick={() => setSelectedBillingPeriod('annual')}
              className={`px-4 py-2 rounded ${selectedBillingPeriod === 'annual' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            >
              Anual
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {plans.map((planOption) => (
              <PricingCard
                key={planOption.plan}
                plan={planOption.plan}
                price={planOption.price}
                currency={planOption.price.currency}
                billingPeriod={selectedBillingPeriod}
                features={planOption.price.features}
                onSelect={handleSubscribe}
                isCurrent={userData.plan === planOption.plan && userData.pagoPlan === selectedBillingPeriod} // Comparar ambos valores
                isLoggedIn={isAuthenticated}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default SubscriptionPage;
