import React, { useState } from 'react';
import { post } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

const CrearClienteModal = ({ isOpen, onClose, onClienteCreated }) => {
    const [cliente, setCliente] = useState({
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
        fechaCumpleanos: '',
        geographic_info: {
            country: '',
            state: '',
            city: '',
            postal_code: ''
        }
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('geographic_info')) {
            const [group, key] = name.split('.');
            setCliente((prev) => ({
                ...prev,
                [group]: {
                    ...prev[group],
                    [key]: value
                }
            }));
        } else {
            setCliente((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!cliente.email && !cliente.telefono) {
            Swal.fire({
                title: 'Error',
                text: 'Debe proporcionar al menos un email o un teléfono',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return;
        }
        try {
            await post('clientes', cliente);
            Swal.fire({
                title: 'Éxito',
                text: 'Cliente creado correctamente',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            });
            onClienteCreated();
            onClose();
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen p-4 text-center sm:p-0">
                {/* Fondo oscuro */}
                <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                    onClick={onClose}
                >
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                {/* Contenido del modal */}
                <div
                    className="bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full max-w-md sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-title"
                >
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="w-full">
                                <h3
                                    className="text-lg leading-6 font-medium text-gray-900 mb-4"
                                    id="modal-title"
                                >
                                    Crear Cliente
                                </h3>
                                <form onSubmit={handleSubmit}>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        {/* Nombre */}
                                        <div>
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor="nombre"
                                            >
                                                Nombre
                                            </label>
                                            <input
                                                type="text"
                                                name="nombre"
                                                value={cliente.nombre}
                                                onChange={handleChange}
                                                required
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            />
                                        </div>

                                        {/* Apellido */}
                                        <div>
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor="apellido"
                                            >
                                                Apellido
                                            </label>
                                            <input
                                                type="text"
                                                name="apellido"
                                                value={cliente.apellido}
                                                onChange={handleChange}
                                                required
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            />
                                        </div>

                                        {/* Email */}
                                        <div className="sm:col-span-2">
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor="email"
                                            >
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                name="email"
                                                value={cliente.email}
                                                onChange={handleChange}
                                                required
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            />
                                        </div>

                                        {/* Teléfono */}
                                        <div>
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor="telefono"
                                            >
                                                Teléfono
                                            </label>
                                            <input
                                                type="text"
                                                name="telefono"
                                                value={cliente.telefono}
                                                onChange={handleChange}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            />
                                        </div>

                                        {/* Fecha de Cumpleaños */}
                                        <div>
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor="fechaCumpleanos"
                                            >
                                                Fecha de Cumpleaños
                                            </label>
                                            <input
                                                type="date"
                                                name="fechaCumpleanos"
                                                value={cliente.fechaCumpleanos}
                                                onChange={handleChange}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            />
                                        </div>

                                        {/* País */}
                                        <div>
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor="geographic_info.country"
                                            >
                                                País
                                            </label>
                                            <input
                                                type="text"
                                                name="geographic_info.country"
                                                value={cliente.geographic_info.country}
                                                onChange={handleChange}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            />
                                        </div>

                                        {/* Estado/Región */}
                                        <div>
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor="geographic_info.state"
                                            >
                                                Estado/Región
                                            </label>
                                            <input
                                                type="text"
                                                name="geographic_info.state"
                                                value={cliente.geographic_info.state}
                                                onChange={handleChange}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            />
                                        </div>

                                        {/* Ciudad */}
                                        <div>
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor="geographic_info.city"
                                            >
                                                Ciudad
                                            </label>
                                            <input
                                                type="text"
                                                name="geographic_info.city"
                                                value={cliente.geographic_info.city}
                                                onChange={handleChange}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            />
                                        </div>

                                        {/* Código Postal */}
                                        <div>
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor="geographic_info.postal_code"
                                            >
                                                Código Postal
                                            </label>
                                            <input
                                                type="text"
                                                name="geographic_info.postal_code"
                                                value={cliente.geographic_info.postal_code}
                                                onChange={handleChange}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            />
                                        </div>
                                    </div>

                                    {/* Botones de Acción */}
                                    <div className="flex justify-end mt-4">
                                        <button
                                            type="button"
                                            onClick={onClose}
                                            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700 mr-2"
                                        >
                                            Cancelar
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                                        >
                                            Crear
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CrearClienteModal;
