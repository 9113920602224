// src/components/pricing/PricingCard.jsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Asegúrate de importar SweetAlert2 si no lo has hecho

const PricingCard = ({
    plan = 'Plan',
    price = { monthly: 0, annual: 0, currency: 'USD', features: [] },
    currency = 'USD',
    billingPeriod = 'monthly',
    features = [],
    onSelect = () => { },
    isCurrent = false,
    isLoggedIn = false,
}) => {
    // Asignar un valor predeterminado a 'currency' si está indefinido
    const displayCurrency = currency ? currency.toUpperCase() : 'USD';

    // Asignar un símbolo de moneda basado en 'currency'
    const currencySymbols = {
        EUR: '€',
        USD: '$',
        CLP: 'CLP$',
        PEN: 'S/',
    };
    const symbol = currencySymbols[displayCurrency] || '$';

    // Determinar si la moneda tiene subunidades
    const isSubunitCurrency = ['EUR', 'USD', 'PEN'].includes(displayCurrency); // Añadido 'PEN'
    const formattedPrice = isSubunitCurrency
        ? ((price[billingPeriod] || 0) / 100).toFixed(2)
        : (price[billingPeriod] || 0).toFixed(0);

    const navigate = useNavigate();

    const handleRegister = () => {
        navigate('/register'); // Asegúrate de que esta ruta existe en tu aplicación
    };

    const handleSelect = () => {
        onSelect(plan);
    };

    return (
        <div className={`bg-white rounded-lg shadow-lg p-6 flex flex-col ${isCurrent ? 'border-4 border-blue-500' : ''}`}>
            <h3 className="text-2xl font-bold mb-4 capitalize text-center">{plan}</h3>
            <p className="text-center text-4xl font-extrabold mb-4">
                {symbol} {formattedPrice}
                <span className="text-lg font-medium">/{billingPeriod === 'monthly' ? 'mes' : 'año'}</span>
            </p>
            <ul className="list-disc list-inside mb-6 flex-1">
                {features.map((feature, index) => (
                    <li key={index} className="text-gray-700">
                        {feature}
                    </li>
                ))}
            </ul>
            {isCurrent ? (
                <button
                    onClick={handleSelect}
                    className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 transition duration-200"
                >
                    Plan Actual
                </button>
            ) : isLoggedIn ? (
                <button
                    onClick={handleSelect}
                    className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 transition duration-200"
                >
                    Seleccionar
                </button>
            ) : (
                <button
                    onClick={handleRegister}
                    className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 transition duration-200"
                >
                    Registrarse
                </button>
            )}
        </div>
    );
};

export default PricingCard;
