// src/components/modals/ConsumedProductsModal.jsx

import React from 'react';

const ConsumedProductsModal = ({ productos, onClose }) => {
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center p-4">
            {/* Fondo oscuro */}
            <div className="fixed inset-0 bg-gray-500 opacity-75" onClick={onClose}></div>

            {/* Contenido del modal */}
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full max-w-lg sm:w-3/4 lg:w-1/2 z-20">
                <div className="px-4 py-5 sm:px-6">
                    <h2 className="text-xl font-bold mb-4 text-center">Productos Consumidos</h2>

                    {productos && productos.length > 0 ? (
                        <table className="min-w-full border">
                            <thead>
                                <tr>
                                    <th className="px-4 py-2 border">Nombre</th>
                                    <th className="px-4 py-2 border">Cantidad</th>
                                    <th className="px-4 py-2 border">Precio</th>
                                    <th className="px-4 py-2 border">Descripción</th>
                                </tr>
                            </thead>
                            <tbody>
                                {productos.map((producto, index) => (
                                    <tr key={index}>
                                        <td className="px-4 py-2 border">{producto.nombre}</td>
                                        <td className="px-4 py-2 border">{producto.cantidad}</td>
                                        <td className="px-4 py-2 border">${producto.precio.toFixed(2)}</td>
                                        <td className="px-4 py-2 border">{producto.descripcion || 'N/A'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p className="text-gray-500">No hay productos consumidos en este registro.</p>
                    )}

                    <div className="flex justify-end mt-4">
                        <button
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                            onClick={onClose}
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConsumedProductsModal;
