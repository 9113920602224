// StatCard.js
import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';

const StatCard = ({ icon, title, value, tooltip }) => (
  <div
    className="bg-white p-4 md:p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer"
    data-tooltip-id="tooltip"
    data-tooltip-content={tooltip}
  >
    <div className="flex items-center mb-2 md:mb-4">
      <div className="text-blue-500 text-2xl md:text-3xl">{icon}</div>
      <div className="ml-2 md:ml-4">
        <span className="text-lg md:text-xl font-semibold text-gray-700">
          {title}
        </span>
      </div>
      <FaInfoCircle className="ml-2 text-gray-400" />
    </div>
    <div className="text-xl md:text-2xl font-bold text-gray-900">
      {value !== undefined ? value : 'No disponible'}
    </div>
  </div>
);

export default StatCard;
