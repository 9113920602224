// src/components/common/Sidebar.js

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faHome, faStore, faTags, faChartBar, faPerson, faBoxes, faBell } from '@fortawesome/free-solid-svg-icons';
import useAuthStore from '../../store/authStore';
import { get } from '../../helpers/apiHelper';

const capitalize = (str) => {
  return str.replace(/\b\w/g, char => char.toUpperCase());
};

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { user } = useAuthStore();
  const [name, setName] = useState('');
  const [firstMarcaId, setFirstMarcaId] = useState('');

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        try {
          const userData = await get(`users/${user.userId}`);
          if (userData.nombre && userData.apellido) {
            setName(capitalize(`${userData.nombre} ${userData.apellido}`));
          }
          const marcas = await get('marcas');
          if (marcas.length > 0) {
            setFirstMarcaId(marcas[0]._id);
          }
        } catch (error) {
          console.error('Failed to fetch user data or marcas:', error);
        }
      };
      fetchUserData();
    }
  }, [user]);

  return (
    <>
      {/* Backdrop */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleSidebar}
        ></div>
      )}

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 w-64 bg-gray-800 text-white transform ${isOpen ? 'translate-x-0' : '-translate-x-full'
          } transition-transform duration-300 ease-in-out z-40`}
        style={{ height: 'calc(100%)' }}
      >
        <div className="px-4 py-2">
          <div className="text-xl font-bold mt-24">{name}</div>
        </div>
        <nav>
          <ul className="space-y-4 p-4">
            <li>
              <Link
                to="/dashboard"
                className="flex items-center space-x-2 py-2 px-2 hover:bg-gray-700 rounded"
                onClick={toggleSidebar}
              >
                <FontAwesomeIcon icon={faHome} size="lg" />
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link
                to="/marcas"
                className="flex items-center space-x-2 py-2 px-2 hover:bg-gray-700 rounded"
                onClick={toggleSidebar}
              >
                <FontAwesomeIcon icon={faTags} size="lg" />
                <span>Marcas</span>
              </Link>
            </li>
            <li>
              <Link
                to="/tiendas"
                className="flex items-center space-x-2 py-2 px-2 hover:bg-gray-700 rounded"
                onClick={toggleSidebar}
              >
                <FontAwesomeIcon icon={faStore} size="lg" />
                <span>Tiendas</span>
              </Link>
            </li>
            <li>
              <Link
                to="/clientes"
                className="flex items-center space-x-2 py-2 px-2 hover:bg-gray-700 rounded"
                onClick={toggleSidebar}
              >
                <FontAwesomeIcon icon={faPerson} size="lg" />
                <span>Clientes</span>
              </Link>
            </li>
            <li>
              <Link
                to="/inventario"
                className="flex items-center space-x-2 py-2 px-2 hover:bg-gray-700 rounded"
                onClick={toggleSidebar}
              >
                <FontAwesomeIcon icon={faBoxes} size="lg" />
                <span>Inventario</span>
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard-surveys"
                className="flex items-center space-x-2 py-2 px-2 hover:bg-gray-700 rounded"
                onClick={toggleSidebar}
              >
                <FontAwesomeIcon icon={faHeart} size="lg" />
                <span>Feedback</span>
              </Link>
            </li>
            <li>
              <Link
                to="/statistics"
                className="flex items-center space-x-2 py-2 px-2 hover:bg-gray-700 rounded"
                onClick={toggleSidebar}
              >
                <FontAwesomeIcon icon={faChartBar} size="lg" />
                <span>Estadísticas</span>
              </Link>
            </li>
            <li>
              <Link
                to="/alertas"
                className="flex items-center space-x-2 py-2 px-2 hover:bg-gray-700 rounded"
                onClick={toggleSidebar}
              >
                <FontAwesomeIcon icon={faBell} size="lg" />
                <span>Alertas</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
