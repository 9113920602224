// src/components/AccumulatePointsModal.jsx

import React, { useState, useEffect } from 'react';

const AccumulatePointsModal = ({
  montoConsumido,
  setMontoConsumido,
  puntosMaximos,
  puntosAcumulados,
  onClose,
  onAccumulate,
  tipoFidelizacion,
  productos,
}) => {
  const [selectedPoints, setSelectedPoints] = useState(1);
  const [puntosDisponibles, setPuntosDisponibles] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    if (tipoFidelizacion === 'PUNTOS') {
      const maxPuntosDisponibles = puntosMaximos - puntosAcumulados;
      const opciones = Array.from({ length: maxPuntosDisponibles }, (_, i) => i + 1);
      setPuntosDisponibles(opciones);
    }
  }, [puntosMaximos, puntosAcumulados, tipoFidelizacion]);

  useEffect(() => {
    if (selectedProducts.length > 0) {
      const total = selectedProducts.reduce((acc, curr) => {
        const producto = productos.find((p) => p._id === curr.productId);
        return acc + (producto?.precio || 0) * curr.cantidad;
      }, 0);
      setMontoConsumido(total);
    } else {
      setMontoConsumido(0);
    }
  }, [selectedProducts, productos, setMontoConsumido]);

  const handleMontoChange = (e) => {
    let value = e.target.value.replace(',', '.'); // Reemplazar coma por punto
    setMontoConsumido(value);
  };

  const handlePointsChange = (e) => {
    setSelectedPoints(Number(e.target.value));
  };

  const handleProductSelection = (productId) => {
    setSelectedProducts((prevSelected) => {
      const existingProduct = prevSelected.find((p) => p.productId === productId);
      if (existingProduct) {
        // Si el producto ya está seleccionado, lo deselecciona
        return prevSelected.filter((p) => p.productId !== productId);
      } else {
        // Si no está seleccionado, lo agrega con cantidad 1 por defecto
        return [...prevSelected, { productId, cantidad: 1 }];
      }
    });
  };

  const handleProductQuantityChange = (productId, cantidad) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.map((p) =>
        p.productId === productId ? { ...p, cantidad: Number(cantidad) } : p
      )
    );
  };

  const handleAccumulate = () => {
    onAccumulate(selectedPoints, selectedProducts);
  };

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto flex items-center justify-center p-4">
      {/* Fondo oscuro */}
      <div className="fixed inset-0 bg-gray-500 opacity-75" onClick={onClose}></div>
      {/* Contenido del modal */}
      <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full max-w-md sm:w-3/4 lg:w-1/2 z-20">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-xl font-bold mb-4 text-center">Acumular Puntos</h2>
          {/* Selección de Productos */}
          <div className="mb-4">
            <label className="block mb-2">Selecciona Productos</label>
            <div className="max-h-48 overflow-y-auto border border-gray-300 rounded p-2">
              {productos && productos.length > 0 ? (
                productos.map((producto) => {
                  const isSelected = selectedProducts.find(
                    (p) => p.productId === producto._id
                  );
                  return (
                    <div key={producto._id} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        id={`product-${producto._id}`}
                        checked={isSelected ? true : false}
                        onChange={() => handleProductSelection(producto._id)}
                        className="mr-2"
                      />
                      <label htmlFor={`product-${producto._id}`} className="flex-1">
                        {producto.nombre} - ${producto.precio.toFixed(2)}
                      </label>
                      {isSelected && (
                        <input
                          type="number"
                          min="1"
                          value={isSelected.cantidad}
                          onChange={(e) =>
                            handleProductQuantityChange(producto._id, e.target.value)
                          }
                          className="w-16 p-1 border border-gray-300 rounded"
                        />
                      )}
                    </div>
                  );
                })
              ) : (
                <p className="text-gray-500">No hay productos disponibles.</p>
              )}
            </div>
          </div>
          {/* Monto Consumido */}
          <div className="mb-4">
            <label className="block mb-2">Monto Consumido</label>
            <input
              type="text"
              placeholder="Monto Consumido"
              value={montoConsumido}
              onChange={handleMontoChange}
              className="mb-4 p-2 border border-gray-300 rounded w-full"
              disabled={selectedProducts.length > 0} // Deshabilitar si hay productos seleccionados
            />
            {selectedProducts.length > 0 && (
              <p className="text-sm text-gray-600">
                El monto consumido se calcula automáticamente basado en los productos seleccionados.
              </p>
            )}
          </div>
          {/* Puntos a Acumular */}
          <div className="mb-4">
            <label className="block mb-2">Puntos a Acumular</label>
            {tipoFidelizacion === 'PUNTOS' ? (
              <select
                value={selectedPoints}
                onChange={handlePointsChange}
                className="mb-4 p-2 border border-gray-300 rounded w-full"
              >
                {puntosDisponibles.map((punto, index) => (
                  <option key={index} value={punto}>
                    {punto} puntos
                  </option>
                ))}
              </select>
            ) : (
              <input
                type="number"
                value={selectedPoints}
                onChange={handlePointsChange}
                className="mb-4 p-2 border border-gray-300 rounded w-full"
                min="1"
              />
            )}
          </div>
          {/* Botones de Acción */}
          <div className="flex justify-end">
            <button
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700 mr-2"
              onClick={onClose}
            >
              Cancelar
            </button>
            <button
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
              onClick={handleAccumulate}
              disabled={
                (tipoFidelizacion === 'PUNTOS' && selectedPoints > puntosDisponibles.length) ||
                montoConsumido <= 0
              }
            >
              Acumular
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccumulatePointsModal;
