import React, { useState, useEffect } from 'react';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import Select from 'react-select';
import {
  FaChartLine,
  FaMoneyBillWave,
  FaRetweet,
  FaInfoCircle
} from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import StatCard from '../../components/statistic/StatCard';

const CustomerStatisticsPage = () => {
  const { clienteId } = useParams();
  const { selectedMarca } = useOutletContext(); // Obtener el ID de la marca seleccionada
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [stats, setStats] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        if (selectedMarca) {

          const response = await get(`marcas/${selectedMarca._id}/clientes`);
          const clienteOptions = response.map(cliente => ({
            value: cliente._id,
            label: `${cliente.nombre} ${cliente.apellido}`
          }));
          setClientes(clienteOptions);

          if (clienteId) {
            const selected = clienteOptions.find(c => c.value === clienteId);
            setSelectedCliente(selected);
          }
        }
      } catch (error) {
        console.error('Error fetching clients:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error al obtener los clientes. ' + error.message,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    };

    fetchClientes();
  }, [clienteId, selectedMarca]);

  useEffect(() => {
    if (selectedCliente) {
      const fetchStats = async () => {
        try {
          const response = await get(`customer-statistics/cliente/${selectedCliente.value}`);
          setStats(response);
        } catch (error) {
          setError('No hay suficientes datos para mostrar estadísticas.');
          console.error('Error fetching statistics:', error);
          Swal.fire({
            title: 'Error',
            text: error.message || 'No hay suficientes datos para mostrar estadísticas.',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        }
      };

      fetchStats();
    }
  }, [selectedCliente]);

  const handleClienteChange = (selectedOption) => {
    setSelectedCliente(selectedOption);
    navigate(`/statistics/cliente/${selectedOption.value}`);
  };

  if (error) {
    return <div className="p-6 text-red-500">{error}</div>;
  }

  if (!stats || !clientes.length) {
    return <div className="p-6">Cargando estadísticas...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4 md:p-6">
      <div className="container mx-auto">
        <h1 className="text-2xl md:text-4xl font-bold mb-4 md:mb-6 text-center text-gray-800">
          Estadísticas del Cliente
        </h1>

        <div className="mb-6">
          <label
            htmlFor="cliente-selector"
            className="block text-lg font-medium text-gray-700"
          >
            Selecciona un Cliente:
          </label>
          <div className="mt-2 w-full md:w-1/2 lg:w-1/3">
            <Select
              id="cliente-selector"
              value={selectedCliente}
              onChange={handleClienteChange}
              options={clientes}
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </div>
        </div>

        {/* Tarjetas de estadísticas */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
          <StatCard icon={<FaChartLine />} title="Total de Puntos" value={stats.totalPuntos} tooltip="Total de puntos acumulados por el cliente." />
          <StatCard icon={<FaRetweet />} title="Total de Canjes" value={stats.totalCanjes} tooltip="Número total de puntos canjeados por el cliente." />
          <StatCard icon={<FaChartLine />} title="Valor Promedio de Transacción" value={stats.averageTransactionValue.toFixed(2)} tooltip="Promedio del valor de cada transacción realizada por el cliente." />
          <StatCard icon={<FaRetweet />} title="Frecuencia de Visitas Diaria" value={stats.visitFrequency.daily.toFixed(2)} tooltip="Promedio de visitas diarias del cliente." />
          <StatCard icon={<FaRetweet />} title="Frecuencia de Visitas Semanal" value={stats.visitFrequency.weekly.toFixed(2)} tooltip="Promedio de visitas semanales del cliente." />
          <StatCard icon={<FaRetweet />} title="Frecuencia de Visitas Mensual" value={stats.visitFrequency.monthly.toFixed(2)} tooltip="Promedio de visitas mensuales del cliente." />
          <StatCard icon={<FaRetweet />} title="Total de Visitas" value={stats.totalVisits} tooltip="Número total de visitas realizadas por el cliente." />
        </div>

        <Tooltip id="tooltip" />
      </div>
    </div>
  );
};

export default CustomerStatisticsPage;
