import React, { useState } from 'react';

const EditClienteModal = ({ cliente, onClose, onSave }) => {
  const [editingCliente, setEditingCliente] = useState(cliente);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('geographic_info')) {
      const [group, key] = name.split('.');
      setEditingCliente((prev) => ({
        ...prev,
        [group]: {
          ...prev[group],
          [key]: value
        }
      }));
    } else {
      setEditingCliente((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSave = () => {
    onSave(editingCliente);
  };
  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <h2 className="text-2xl font-bold mb-4">Editar Cliente</h2>
          <form>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nombre">
                Nombre
              </label>
              <input
                type="text"
                name="nombre"
                value={editingCliente.nombre}
                onChange={handleChange}
                className="mb-4 p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="apellido">
                Apellido
              </label>
              <input
                type="text"
                name="apellido"
                value={editingCliente.apellido}
                onChange={handleChange}
                className="mb-4 p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={editingCliente.email}
                onChange={handleChange}
                className="mb-4 p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="telefono">
                Teléfono
              </label>
              <input
                type="text"
                name="telefono"
                value={editingCliente.telefono}
                onChange={handleChange}
                className="mb-4 p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fechaCumpleanos">
                Fecha de Nacimiento
              </label>
              <input
                type="date"
                name="fechaCumpleanos"
                value={editingCliente.fechaCumpleanos}
                onChange={handleChange}
                className="mb-4 p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="geographic_info.country">
                País
              </label>
              <input
                type="text"
                name="geographic_info.country"
                value={editingCliente.geographic_info.country}
                onChange={handleChange}
                className="mb-4 p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="geographic_info.state">
                Estado/Región
              </label>
              <input
                type="text"
                name="geographic_info.state"
                value={editingCliente.geographic_info.state}
                onChange={handleChange}
                className="mb-4 p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="geographic_info.city">
                Ciudad
              </label>
              <input
                type="text"
                name="geographic_info.city"
                value={editingCliente.geographic_info.city}
                onChange={handleChange}
                className="mb-4 p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="geographic_info.postal_code">
                Código Postal
              </label>
              <input
                type="text"
                name="geographic_info.postal_code"
                value={editingCliente.geographic_info.postal_code}
                onChange={handleChange}
                className="mb-4 p-2 border border-gray-300 rounded w-full"
              />
            </div>
          </form>
          <div className="flex justify-end">
            <button
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700 mr-2"
              onClick={onClose}
            >
              Cancelar
            </button>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
              onClick={handleSave}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditClienteModal;
