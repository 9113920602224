// src/pages/cliente/Settings.jsx

import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { post, del, get } from '../../helpers/apiHelper';  // Asegúrate de que esta es la ubicación correcta
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../store/authStore';

function Settings() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [currentPlan, setCurrentPlan] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { logout, user, trabajador, isAuthenticated } = useAuthStore();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user && user.userId) {
          const response = await get(`users/${user.userId}`);
          if (response) {
            setUserData(response);
            setCurrentPlan(response.plan);
          }
        } else {
          throw new Error('User ID is undefined');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        Swal.fire('Error', 'No se pudo obtener los datos del usuario.', 'error').then(() => {
          navigate('/login'); // Redirigir al login si hay un error
        });
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchUserData();
    } else {
      setLoading(false);
      Swal.fire('Error', 'Usuario no autenticado.', 'error').then(() => {
        navigate('/login'); // Redirigir al login si no está autenticado
      });
    }
  }, [user, navigate, isAuthenticated]);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (!oldPassword || !newPassword || !confirmNewPassword) {
      Swal.fire('Error', 'Todos los campos de contraseña son obligatorios.', 'error');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      Swal.fire('Error', 'Las contraseñas nuevas no coinciden.', 'error');
      return;
    }

    Swal.fire({
      title: '¿Estás seguro?',
      text: "¿Deseas cambiar tu contraseña?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, cambiarla',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await post('auth/change-password', { oldPassword, newPassword });
          Swal.fire('¡Actualizado!', 'Tu contraseña ha sido cambiada.', 'success').then(() => {
            navigate('/dashboard');  // Redirige al dashboard después de cambiar la contraseña
          });
        } catch (error) {
          Swal.fire('Error', error.message, 'error');
        }
      }
    });
  };

  const handleDeleteAccount = async () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¿Realmente deseas eliminar tu cuenta? Este proceso no puede deshacerse.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, eliminarla',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await del('auth/delete-account');
          logout();
          Swal.fire('¡Eliminado!', 'Tu cuenta ha sido eliminada.', 'success').then(() => {
            navigate('/');  // Redirige a la página principal después de eliminar la cuenta
          });
        } catch (error) {
          Swal.fire('Error', error.message, 'error');
        }
      }
    });
  };

  const handleNavigateToSubscribe = () => {
    navigate('/subscribe');
  };

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 p-4">
        <div className="text-xl">Cargando...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 p-4">
      <h1 className="text-2xl font-semibold mb-6">Configuración</h1>

      {/* Información del Usuario */}
      {userData && (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-6 w-full max-w-md">
          <h2 className="text-xl font-bold mb-4">Información del Usuario</h2>
          <p className="text-gray-700"><span className="font-semibold">Nombre:</span> {userData.nombre}</p>
          <p className="text-gray-700"><span className="font-semibold">Apellido:</span> {userData.apellido}</p>
          <p className="text-gray-700"><span className="font-semibold">Correo Electrónico:</span> {userData.email}</p>
          <p className="text-gray-700"><span className="font-semibold">País:</span> {userData.pais}</p>
          <p className="text-gray-700"><span className="font-semibold">Rol:</span> {trabajador ? 'TRABAJADOR_ROLE' : 'USER_ROLE'}</p>
        </div>
      )}

      {/* Información del Plan y Suscripción (Solo para USER_ROLE) */}
      {!trabajador && userData && (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-6 w-full max-w-md">
          <h2 className="text-xl font-bold mb-4">Plan Actual</h2>
          <p className="text-gray-700"><span className="font-semibold">Plan:</span> {userData.plan.charAt(0).toUpperCase() + userData.plan.slice(1)}</p>
          <button
            onClick={handleNavigateToSubscribe}
            className="mt-4 w-full py-2 px-4 bg-blue-500 hover:bg-blue-700 text-white font-semibold rounded-md"
          >
            Cambiar Suscripción
          </button>
        </div>
      )}

      {/* Formulario para Cambiar Contraseña */}
      <form onSubmit={handleChangePassword} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-6 w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">Cambiar Contraseña</h2>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Contraseña Actual:
          </label>
          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="********"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Nueva Contraseña:
          </label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="********"
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Confirmar Nueva Contraseña:
          </label>
          <input
            type="password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="********"
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Cambiar Contraseña
          </button>
        </div>
      </form>

      {/* Botón para Eliminar Cuenta */}
      <button
        onClick={handleDeleteAccount}
        className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full max-w-md"
      >
        Eliminar Cuenta
      </button>
    </div>
  );
}

export default Settings;
