// src/components/modals/RedeemPremiosModal.jsx
import React, { useState, useEffect } from 'react';
import { get, patch } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

function RedeemPremiosModal({ cliente, onClose, trabajadorId, onRedeemSuccess }) {
  const [premios, setPremios] = useState([]);

  useEffect(() => {
    fetchPremios();
  }, []);

  const fetchPremios = async () => {
    try {
      const response = await get(`marcas/${cliente.marca._id}/premios`);
      setPremios(response.filter(premio => premio.activo && premio.puntosNecesarios <= cliente.ticket.puntos));
    } catch (error) {
      console.error('Error fetching premios:', error);
    }
  };

  const handleRedeemPremio = async (premioId) => {
    try {
      const payload = {
        premioId,
        trabajadorId
      };
      await patch(`tickets/${cliente.ticket._id}/redeem-premio/${premioId}`, payload);
      onRedeemSuccess();
      onClose();
      Swal.fire({
        title: 'Éxito',
        text: 'Premio canjeado correctamente',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };


  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-lg w-full p-6">
          <div className="flex justify-between items-center pb-3">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Canjear Premios</h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          {premios.length > 0 ? (
            premios.map((premio) => (
              <div key={premio._id} className="p-4 mb-4 border rounded flex justify-between items-center">
                <div>
                  <h5 className="text-xl font-bold">{premio.nombre}</h5>
                  <p>{premio.descripcion}</p>
                  <p>{`Puntos Necesarios: ${premio.puntosNecesarios}`}</p>
                </div>
                <button
                  onClick={() => handleRedeemPremio(premio._id)}
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                  Canjear
                </button>
              </div>
            ))
          ) : (
            <p>No hay premios disponibles para canjear.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default RedeemPremiosModal;
