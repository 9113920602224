import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { get, post } from '../../helpers/apiHelper';
import img1 from "../../assets/1.png";
import img2 from "../../assets/2.png";
import img3 from "../../assets/3.png";
import img4 from "../../assets/4.png";
import img5 from "../../assets/5.png";
import { validateEmail } from '../../helpers/validate';

function SurveyPage() {
    const { surveyId } = useParams();
    const [survey, setSurvey] = useState(null);
    const [responses, setResponses] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        get(`surveys/${surveyId}`).then(data => {
            setSurvey(data);
            setLoading(false);
        }).catch(err => {
            setError('Failed to load survey');
            setLoading(false);
        });
    }, [surveyId]);

    const handleChange = (questionIndex, option) => {
        // Use a unique key for each question based on its index
        setResponses(prev => ({
            ...prev,
            [questionIndex]: option
        }));
    };

    const handleSubmit = async () => {
        if (!survey.isActive) {
            setError('This survey is currently not active.');
            return;
        }

        const unansweredQuestions = survey.questions.some((q, index) => q.options && !responses[index]);
        if (unansweredQuestions) {
            Swal.fire({
                icon: 'error',
                title: 'Encuesta Incompleta',
                text: 'Por favor, responde todas las preguntas obligatorias antes de enviar la encuesta.',
                confirmButtonColor: '#dc3545',
            });
            return;
        }

        // First confirm submission
        Swal.fire({
            title: '¿Estás listo para subir la enquesta?',
            text: 'Puedes revisar las respuestas o subir tus opiniones y ayudarnos a mejorar.',
            icon: 'question',
            showDenyButton: true,
            confirmButtonText: 'Enviar',
            denyButtonText: 'Revisar',
        }).then((result) => {
            if (result.isConfirmed) {
                collectContactInfo();
            } else if (result.isDenied) {
                Swal.fire('Revisa tus respuestas');
            }
        });
    };

    const collectContactInfo = () => {
        Swal.fire({
            title: '¿Nos quieres dejar tus datos para contactar y mejorar la experiencia?',
            html: `
                <input type="text" id="name" class="swal2-input custom-input" placeholder="Su nombre (opcional)">
                <input type="email" id="email" class="swal2-input custom-input" placeholder="Su Email (opcional)">
                <input type="text" id="phone" class="swal2-input custom-input" placeholder="Su teléfono (opcional)">`,
            focusConfirm: false,
            preConfirm: () => {
                const name = Swal.getPopup().querySelector('#name').value;
                const email = Swal.getPopup().querySelector('#email').value;
                const phone = Swal.getPopup().querySelector('#phone').value;

                return { name, email, phone };
            }
        }).then((result) => {
            if (result.value) {
                submitSurvey(result.value);
            }
        });
    };

    const submitSurvey = (contactInfo) => {
        // Si el email está vacío o es inválido, lo eliminamos del objeto antes de enviarlo
        if (!contactInfo.email || !validateEmail(contactInfo.email)) {
            delete contactInfo.email;
        }

        const payload = {
            surveyId,
            answers: responses,
            ...contactInfo
        };

        post(`survey-responses/${surveyId}`, payload)
            .then(() => {
                Swal.fire({
                    title: 'Encuesta enviada!',
                    text: 'Muchas gracias por tus comentarios.',
                    icon: 'success',
                    confirmButtonColor: '#3085d6'
                });
                // Limpiar las respuestas después de enviar
                setResponses({});
            })
            .catch((err) => {
                console.error('Failed to submit survey', err);
                setError('Failed to submit survey');
                Swal.fire({
                    title: 'Ha habido un error al enviar la encuesta',
                    text: 'Hubo un problema al enviar su encuesta.',
                    icon: 'error',
                    confirmButtonColor: '#dc3545'
                });
            });
    };

    if (loading) return <p className="text-center text-lg">Loading...</p>;
    if (error)
        return (
            <div className="flex flex-col justify-center items-center min-h-screen">
                <div className="text-center">
                    <h3 className="text-lg font-semibold text-red-600">
                        Esta encuesta está temporalmente deshabilitada o ya no se aceptan
                        más respuestas.
                    </h3>
                    <p className="mt-2">
                        Por favor, habla con un empleado o intenta acceder más tarde. Gracias
                        por tu comprensión.
                    </p>
                </div>
            </div>
        );
    if (!survey) return <p className="text-lg text-center">Enquesta no encontrada.</p>;

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-2xl md:text-3xl font-bold text-center mb-6">
                {survey.title}
            </h1>
            {survey.questions.map((q, questionIndex) => (
                <div key={questionIndex} className="mb-8">
                    <h2 className="text-lg md:text-xl font-semibold text-center">
                        {q.question}
                    </h2>
                    <div className="flex flex-wrap justify-center items-center space-x-4 mt-4">
                        {q.options ? (
                            q.options.map((option, optionIndex) => (
                                <div
                                    key={optionIndex}
                                    className={`flex flex-col items-center cursor-pointer m-2 ${responses[questionIndex] === option
                                            ? 'ring-4 ring-indigo-500'
                                            : ''
                                        }`}
                                    onClick={() => handleChange(questionIndex, option)}
                                >
                                    <img
                                        src={[img1, img2, img3, img4, img5][optionIndex]}
                                        alt={`Option ${option}`}
                                        className="w-12 h-12 md:w-16 md:h-16"
                                    />
                                    <span className="mt-2">{option}</span>
                                </div>
                            ))
                        ) : (
                            <textarea
                                className="mt-2 p-2 border border-gray-300 rounded-md w-full md:w-3/4"
                                value={responses[questionIndex] || ''}
                                onChange={(e) => handleChange(questionIndex, e.target.value)}
                            />
                        )}
                    </div>
                </div>
            ))}
            <div className="text-center">
                <button
                    onClick={handleSubmit}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Enviar
                </button>
            </div>
        </div>
    );
}

export default SurveyPage;
