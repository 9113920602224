import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

const renderStars = (currentPoints, totalPoints) => {
  const stars = [];
  for (let i = 0; i < totalPoints; i++) {
    if (i < currentPoints) {
      stars.push(<span key={i} className="text-yellow-400 text-2xl">&#9733;</span>); // Estrella llena
    } else {
      stars.push(<span key={i} className="text-gray-300 text-2xl">&#9733;</span>); // Estrella vacía
    }
  }
  return stars;
};

const TicketView = () => {
  const { ticketUrl } = useParams();
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTicket = async () => {
      try {
        const response = await get(`tickets/url/${ticketUrl}`);
        setTicket(response);
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchTicket();
  }, [ticketUrl]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-xl">Cargando...</div>
      </div>
    );
  }

  if (!ticket) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-xl">Ticket no encontrado</div>
      </div>
    );
  }

  const accumulatedPoints = ticket.puntos || 0;
  console.log(ticket);

  return (
    <div className="container mx-auto my-8 p-6 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-lg shadow-xl">
      <div className="text-center mb-8">
        <h1 className="text-3xl md:text-5xl font-bold mb-2">Tarjeta de Fidelización</h1>
        <p className="text-lg text-gray-200">¡Gracias por ser parte de nuestra familia!</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Información del Cliente */}
        <div className="bg-white bg-opacity-20 p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Cliente</h2>
          <p className="text-lg">
            <strong>Nombre:</strong> {ticket.cliente?.nombre} {ticket.cliente?.apellido}
          </p>
          <p className="text-lg">
            <strong>Email:</strong> {ticket.cliente?.email}
          </p>
        </div>
        {/* Información de la Tienda */}
        <div className="bg-white bg-opacity-20 p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Tienda</h2>
          <p className="text-lg">
            <strong>Nombre:</strong> {ticket.tienda?.nombre}
          </p>
          <p className="text-lg">
            <strong>Ubicación:</strong> {ticket.tienda?.via} {ticket.tienda?.calle}{' '}
            {ticket.tienda?.numero}, {ticket.tienda?.poblacion}, {ticket.tienda?.region},{' '}
            {ticket.tienda?.pais}
          </p>
        </div>
        {/* Información de la Marca */}
        <div className="bg-white bg-opacity-20 p-6 rounded-lg shadow-md md:col-span-2">
          <h2 className="text-2xl font-semibold mb-4">Marca</h2>
          <p className="text-lg">
            <strong>Nombre:</strong> {ticket.marca?.nombre}
          </p>
        </div>
        {/* Detalles del Ticket */}
        <div className="bg-white bg-opacity-20 p-6 rounded-lg shadow-md md:col-span-2">
          <h2 className="text-2xl font-semibold mb-4">Detalles del Ticket</h2>
          <p className="text-lg">
            <strong>Fecha de Creación:</strong>{' '}
            {new Date(ticket.created_at).toLocaleDateString()}
          </p>
          <p className="text-lg">
            <strong>Tipo de Fidelización:</strong> {ticket.tipoFidelizacion}
          </p>
          {ticket.tipoFidelizacion === 'PUNTOS' ? (
            <div className="mt-6">
              <h3 className="text-xl font-semibold mb-4">Progreso de Puntos</h3>
              <div className="flex flex-col items-center">
                <div className="flex">
                  {renderStars(ticket.puntos, ticket.marca.puntosTicket)}
                </div>
                <p className="mt-2 text-lg">
                  Te faltan <strong>{ticket.marca.puntosTicket - ticket.puntos} puntos</strong> para completar
                </p>
              </div>
            </div>
          ) : (
            <div className="mt-6">
              <h3 className="text-xl font-semibold mb-4">Puntos Acumulados</h3>
              <div className="flex flex-col items-center">
                <p className="text-4xl font-bold">{accumulatedPoints} puntos</p>
                <p className="mt-2 text-lg text-center">
                  Puedes canjear tus puntos por premios en la tienda.
                </p>
                <p className="mt-2 text-sm text-gray-200">
                  Visita la tienda para conocer los premios disponibles.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketView;
