import React, { useState, useMemo } from 'react';
import Swal from 'sweetalert2';

const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };
  return new Date(dateString).toLocaleString('ES', options);
};

const CriticalFeedback = ({ feedback }) => {
  const [selectedFeedback, setSelectedFeedback] = useState(null);

  const handleDetailsClick = (responses) => {
    const detailsHtml = responses.map(res => `
      <div>
        <p><strong>Pregunta:</strong> ${res.question}</p>
        <p><strong>Respuesta:</strong> ${res.answer}</p>
      </div>
    `).join('');
    Swal.fire({
      title: 'Respuestas con puntuación baja',
      html: detailsHtml,
      icon: 'info',
      confirmButtonText: 'Cerrar'
    });
  };

  const groupedFeedback = useMemo(() => feedback.reduce((acc, item) => {
    const key = item.email || item.name || 'Anónimo';
    if (!acc[key]) {
      acc[key] = {
        name: item.name || 'Anónimo',
        email: item.email || 'N/A',
        phone: item.phone || 'N/A',
        date: formatDate(item.date),
        responses: []
      };
    }
    acc[key].responses.push({
      question: item.question,
      answer: item.answer,
      isCritical: parseInt(item.answer, 10) <= 2
    });
    return acc;
  }, {}), [feedback]);

  return (
    <div className="mt-8">
      <h2 className="text-xl md:text-2xl font-semibold mb-4">
        Feedback Crítico
      </h2>
      <div className="overflow-x-auto">
        <table className="min-w-full mb-4">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 text-left text-xs md:text-sm">Fecha</th>
              <th className="px-4 py-2 text-left text-xs md:text-sm">Nombre</th>
              <th className="px-4 py-2 text-left text-xs md:text-sm">Email</th>
              <th className="px-4 py-2 text-left text-xs md:text-sm">Teléfono</th>
              <th className="px-4 py-2 text-left text-xs md:text-sm">Acción</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(groupedFeedback).map(([key, user], index) => (
              <tr key={index} className="border-b">
                <td className="px-4 py-2 text-xs md:text-sm">{user.date}</td>
                <td className="px-4 py-2 text-xs md:text-sm">{user.name}</td>
                <td className="px-4 py-2 text-xs md:text-sm">{user.email}</td>
                <td className="px-4 py-2 text-xs md:text-sm">{user.phone}</td>
                <td className="px-4 py-2 text-xs md:text-sm">
                  <button onClick={() => handleDetailsClick(user.responses)}
                    className="cursor-pointer px-4 py-1 border border-transparent rounded bg-blue-500 text-white hover:bg-blue-600 text-xs md:text-sm">
                    Ver Detalles
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CriticalFeedback;
