// src/components/modals/EditProductoModal.jsx
import React, { useState, useEffect } from 'react';
import { put, get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

function EditProductoModal({ isOpen, producto, onClose, onProductoUpdated }) {
    const [nombre, setNombre] = useState(producto.nombre);
    const [cantidad, setCantidad] = useState(producto.cantidad);
    const [precio, setPrecio] = useState(producto.precio);
    const [descripcion, setDescripcion] = useState(producto.descripcion);
    const [tiendas, setTiendas] = useState([]);
    const [selectedTiendas, setSelectedTiendas] = useState(producto.tiendas.map(tienda => tienda._id));

    useEffect(() => {
        if (isOpen && producto) {
            setNombre(producto.nombre);
            setCantidad(producto.cantidad);
            setPrecio(producto.precio);
            setDescripcion(producto.descripcion);

            // Inicializa las tiendas seleccionadas
            setSelectedTiendas(producto.tiendas);
            fetchTiendas();
        }
    }, [isOpen, producto]);

    const fetchTiendas = async () => {
        try {
            const response = await get('tiendas');
            setTiendas(response);
        } catch (error) {
            console.error('Error fetching tiendas:', error);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const handleUpdate = async () => {
        if (selectedTiendas.length === 0) {
            Swal.fire({
                title: 'Error',
                text: 'El producto debe estar asociado a al menos una tienda.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
            return;
        }
        try {
            await put(`productos/${producto._id}`, { nombre, cantidad: Number(cantidad), precio: Number(precio), descripcion, tiendas: selectedTiendas });
            Swal.fire({
                title: 'Producto Actualizado',
                text: 'El producto ha sido actualizado exitosamente',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            });
            onProductoUpdated();
            onClose();
        } catch (error) {
            console.error('Error updating producto:', error);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const toggleTiendaSelection = (tiendaId) => {
        setSelectedTiendas((prevSelected) =>
            prevSelected.includes(tiendaId)
                ? prevSelected.filter((id) => id !== tiendaId)
                : [...prevSelected, tiendaId]
        );
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg w-1/3">
                <h2 className="text-2xl font-bold mb-4">Editar Producto</h2>
                <label className="block mb-2">Nombre</label>
                <input
                    type="text"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    className="w-full mb-4 p-2 border rounded"
                />
                {/* <label className="block mb-2">Cantidad</label>
                <input
                    type="number"
                    value={cantidad}
                    onChange={(e) => setCantidad(e.target.value)}
                    className="w-full mb-4 p-2 border rounded"
                /> */}
                <label className="block mb-2">Precio</label>
                <input
                    type="text" // Cambiar a "text" para permitir el ingreso de coma
                    value={precio}
                    onChange={(e) => {
                        const input = e.target.value.replace(',', '.'); // Reemplazar coma por punto
                        if (/^\d*\.?\d*$/.test(input)) { // Validar formato numérico
                            setPrecio(input); // Actualizar el estado con el nuevo valor
                        }
                    }}
                    onBlur={() => {
                        // Convertir el valor a número real en el estado al perder el foco
                        if (precio) {
                            setPrecio(parseFloat(precio) || 0); // Asegurar un número válido
                        }
                    }}
                    className="w-full mb-4 p-2 border rounded"
                />
                <label className="block mb-2">Descripción</label>
                <textarea
                    value={descripcion}
                    onChange={(e) => setDescripcion(e.target.value)}
                    className="w-full mb-4 p-2 border rounded"
                ></textarea>
                <label className="block mb-2">Tiendas</label>
                <div className="flex flex-wrap gap-2 mb-4">
                    {tiendas.map((tienda) => (
                        tienda && tienda._id && (
                            <button
                                key={tienda._id}
                                onClick={() => toggleTiendaSelection(tienda._id)}
                                className={`px-4 py-2 rounded ${selectedTiendas.includes(tienda._id) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                            >
                                {tienda.nombre || 'Tienda sin nombre'}
                            </button>
                        )
                    ))}
                </div>
                <div className="flex justify-end space-x-4">
                    <button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700">
                        Cancelar
                    </button>
                    <button onClick={handleUpdate} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">
                        Actualizar
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditProductoModal;
